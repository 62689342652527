// import Link from 'next/link'
import { Link, useLocation } from "@remix-run/react";
import { useGlobalContext } from "../context/GlobalContext";
import { isValidUrl } from "~/utils/isValidUrl";
import { i18n } from "~/i18n.config";

const locales: string[] = i18n.locales;
// should probably pull this out into utils...
function cleanPathname(pathname: string): string {
  let cleanPath = pathname;
  locales.forEach((locale) => {
    if (cleanPath.startsWith(`/${locale}/`)) {
      cleanPath = cleanPath.replace(`/${locale}/`, "/");
    }
    if (cleanPath == `/${locale}`) {
      cleanPath = cleanPath.replace(`/${locale}`, "/");
    }
  });
  return cleanPath;
}

export default function Navbar({ config }): JSX.Element {
  const navLinks = config.links;
  const { pathname: path } = useLocation();
  const cleanpath = cleanPathname(path);

  const { locale, setPopup, mobileNav, setMobileNav } = useGlobalContext();

  function hamburgerClick() {
    if (mobileNav == true) {
      setMobileNav(false);
    } else {
      setMobileNav(true);
    }
  }

  return (
    <>
      <nav
        className={
          mobileNav
            ? "navbar mobile-open"
            : "navbar mobile-closed"
        }
      >
        <div className="navbar-brand">
          <a className="navbar-item py-0" href={`/${locale}/`}>
            <img
              src="/images/RLS_Logo_Final_RGB.png"
              alt="Rocket League Sideswipe"
            />
          </a>
          <div
            className="navbar-burger"
            data-target="navbar"
            onClick={(e) => hamburgerClick(e)}
            onKeyDown={(e) => hamburgerClick(e)}
            role="presentation"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div id="navbar" className="navbar-menu">
          <div className="navbar-start">
            {navLinks.map((navLink: any) =>
              isValidUrl(navLink.href) ? (
                <Link
                  key={navLink.href}
                  to={navLink.href}
                  className={`navbar-item has-text-light is-uppercase`}
                >
                  {navLink.title}
                </Link>
              ) : (
                <Link
                  key={navLink.href}
                  to={`/${locale}${navLink.href}`}
                  className={`navbar-item has-text-light is-uppercase`}
                >
                  {navLink.title}
                </Link>
              )
            )}
          </div>

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link has-text-light" href="#">
                <img
                  src="/images/icons/icon-locale.svg"
                  width="20px"
                  height="20px"
                  alt="Select Locale"
                />
              </a>
              <div className="navbar-dropdown is-boxed">
                <Link
                  className="navbar-item has-text-light"
                  to={`/en${cleanpath}`}
                  reloadDocument
                >
                  English
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/de${cleanpath}`}
                  reloadDocument
                >
                  Deutsch
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/fr${cleanpath}`}
                  reloadDocument
                >
                  Français
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/pt-br${cleanpath}`}
                  reloadDocument
                >
                  Português (Brasil)
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/es-es${cleanpath}`}
                  reloadDocument
                >
                  Español (Spain)
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/es-mx${cleanpath}`}
                  reloadDocument
                >
                  Español (LA)
                </Link>
                <Link
                  className="navbar-item has-text-light"
                  to={`/it${cleanpath}`}
                  reloadDocument
                >
                  Italiano
                </Link>
              </div>
            </div>
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <a
                    className="button is-info is-uppercase"
                    href={`/${locale}/download`}
                  >
                    <span>
                      <strong>{config.callToAction?.title}</strong>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
